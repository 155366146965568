<template>
  <SiteTemplateV2 :highlight="true" v-if="clubVersion === 'v2'">
    <HighLight :isMobile="isMobile" />
    <div class="container-category">
      <Category 
        v-for="(category, i) in AllHome"
        :key="`item-course-home-${i}`"
        :category="category"
        ratio="16:9"
        :permission="permission"
        :homeVertical="homeVertical"
      />
    </div>
  </SiteTemplateV2>
  <div v-else>
    <div v-if="!loading">
      <SiteTemplate>
        <div class="row">
          <Notifications />
        </div>
        <div class="row" v-if="AllBanners.length !== 0">
          <div
            data-anima="top"
            :class="{ spaceHome: !isMobile, spaceHomeMobile: isMobile }"
          >
            <Carrousel
              :isMobile="isMobile"
              :AllBanners="isMobile ? mobileBanners : desktopBanners"
            />
          </div>
        </div>
        <div class="row" v-if="AllCards.length !== 0">
          <div
            data-anima="top"
            :class="{ spaceHome: !isMobile, spaceHomeMobile: isMobile }"
          >
            <CardList
              :isMobile="isMobile"
              :searchCourse="searchCourse"
              :AllCards="AllCards"
            />
          </div>
        </div>
        <div class="row" v-else>
          <div class="spaceDivHome"></div>
        </div>
        <span>
          <div
            v-if="!isMobile && permission"
            data-anima="top"
            :class="{
              spaceBtnsHome: !withoutCategory,
              spaceBtnsHome2: withoutCategory,
            }"
          >
            <a
              v-if="!withoutCategory && countCourse > 1"
              class="router-curso"
              @click="ordenarCursos"
            >
              <button class="btnGerenciar">
                {{ btnGerenciar }}
                <span v-if="btnGerenciar === 'Re-ordenar'">{{
                  $t("home2.course")
                }}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 14H14V21H21V14Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 14H3V21H10V14Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 3H14V10H21V3Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 3H3V10H10V3Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </a>
            <div v-else-if="!withoutCategory && countCourse <= 1"></div>
            <router-link to="/banners" class="router-curso">
              <button class="btnGerenciar">
                {{ $t("home2.gerenciar") }}
                <span>{{ $t("home2.banners") }}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 15L16 10L5 21"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </router-link>
            <router-link to="/cards" class="router-curso">
              <button class="btnGerenciar">
                {{ $t("home2.gerenciar") }} <span>{{ $t("home2.cards") }}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 15L16 10L5 21"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </router-link>
            <router-link to="/config_curso" class="router-curso">
              <button class="btn-NewCourse" id="step3">
                {{ $t("home2.make") }}
                <svg
                  width="7"
                  height="9"
                  viewBox="0 0 7 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.604614 8.43047C0.604614 8.85764 1.10567 9.0881 1.43001 8.8101L5.99505 4.89721C6.22785 4.69766 6.22785 4.3375 5.99505 4.13795L1.43001 0.22506C1.10567 -0.0529427 0.604614 0.177512 0.604614 0.604689L0.604614 8.43047Z"
                    fill="white"
                  />
                </svg>
              </button>
            </router-link>
          </div>
          <CourseList
            data-anima="top"
            :withoutCategory="withoutCategory"
            :permission="permission"
            :isMobile="isMobile"
            :AllHome="AllHome"
          />
        </span>
        <div data-anima="top" class="row" v-if="AllBanners2.length !== 0">
          <div :class="{ spaceHome: !isMobile, spaceHomeMobile: isMobile }">
            <Carrousel :isMobile="isMobile" :AllBanners="AllBanners2" />
          </div>
        </div>
        <FooterBase type="home" />
        <my-component-intro></my-component-intro>
        <modal-buy></modal-buy>
        <modal-ordenar-curso :AllHome="AllHome"></modal-ordenar-curso>
      </SiteTemplate>
      <div class="menu-lateral">
        <slidebar />
      </div>
    </div>
    <!-- Loading -->
    <div class="d-flex justify-content-center mt-5" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <Cards v-show="false" />
  </div>
</template>

<script>
import SiteTemplate from "@/templates/SiteTemplate.vue";
import SiteTemplateV2 from "@/templates/SiteTemplateV2.vue";
import Slidebar from "@/components/Slidebar.vue";
import ModalOrdenarCurso from "@/components/ModalOrdenarCurso.vue";
import CardList from "@/components/home/CardList.vue";
import CourseList from "@/components/home/CourseList.vue";
import Notifications from "@/components/notifications/index.vue";
import Carrousel from "@/components/home/Carrousel.vue";
import FooterBase from "@/components/FooterBase.vue";
import ModalBuy from "@/components/ModalBuy.vue";
import Cards from "@/views/Cards.vue";
import Cookies from "js-cookie";
import HighLight from "@/components/V2/highLight/index.vue"
import Category from "@/components/V2/category/index.vue"
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import HomeService from "@/services/resources/HomeService";

const serviceHome = HomeService.build();

export default {
  name: "Home2",
  components: {
    Cards,
    Slidebar,
    SiteTemplate,
    ModalOrdenarCurso,
    CardList,
    FooterBase,
    CourseList,
    Carrousel,
    ModalBuy,
    Notifications,
    SiteTemplateV2,
    HighLight,
    Category
  },
  data() {
    return {
      client: {
        width: 0,
      },
      homeVertical: false,
      permission: false,
      hideCourses: null,
      AllHome: [],
      painelName: "",
      searchCourse: "",
      AllCards: [],
      AllBanners: [],
      AllBanners2: [],
      withoutCategory: false,
      btnGerenciar: "Re-ordenar",
      countCourse: 0,
      loading: true,
    };
  },
  computed: {
    isMobile() {
      return document.body.clientWidth <= 576;
    },
    mobileBanners() {
      return this.AllBanners.filter((item) => item.mobile_media);
    },
    desktopBanners() {
      return this.AllBanners.filter((item) => item.media);
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  created() {
    this.getHomeVertical();

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    ordenarCursos() {
      if (this.btnGerenciar === "Re-ordenar") {
        this.btnGerenciar = this.$t("home2.back_to_list");
        this.$root.$emit("orderOn");
        setTimeout(() => {
          let cursos = document.getElementsByClassName("spaceCourcex");
          for (let i = 0; i < cursos.length; i++) {
            cursos[i].classList.add("shake");
            setTimeout(() => {
              cursos[i].classList.remove("shake");
            }, 3000);
          }
        }, 100);
      } else {
        this.btnGerenciar = "Re-ordenar";
        this.getHome();
        this.$root.$emit("orderOff");
      }
    },
    handleResize() {
      if (
        (window.matchMedia("(orientation: landscape)").matches &&
          window.outerWidth < 1000) ||
        (window.matchMedia("(orientation: portrait)").matches &&
          window.outerWidth < 769 &&
          window.outerHeight < 1025)
      ) {
        this.client.width = 0;
      } else {
        this.client.width = window.outerWidth;
      }
    },
    getPermission() {
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          this.permission = false;
          return;
        }
        this.permission = true;
      } else {
        this.permission = false;
      }
    },
    resolveCards(cards_array) {
      let todos_templates = "";
      cards_array.forEach((cards) => {
        let { id, link, media, size } = cards;

        var template = `
          <div class="col-md-${size} col-12" data-id-card="${id}">
            <a href="${link}" target="_blank">
              <div class="cardHome" data-midia="${media.id}" style="background-image: url(${media.cdn_url});">
              </div>
            </a>
          </div>
        `;
        todos_templates = todos_templates + template;
      });
      this.rawHtmlCards = todos_templates;
    },
    resolveHome(resp) {
      this.AllCards = resp.cards;
      this.AllBanners = resp.banners_top;
      this.AllBanners2 = resp.banners_bottom;
      let metaHide = resp.hide_courses;
      let categorias = resp.categories;

      let allCourses = resp.courses;
      for (let i = 0; i < allCourses.length; i++) {
        const modules = allCourses[i].modules;
        if (modules) {
          for (let x = 0; x < modules.length; x++) {
            modules[x].has_access = allCourses[i].has_access;
            modules[x].curso = allCourses[i];
          }
        }
      }

      if (resp.without_category === "on") {
        this.withoutCategory = true;
        let cursos = resp.courses;
        let x = cursos.length;
        while (x--) {
          const curso = cursos[x];
          let lessons_completed = curso.lessons_completed_count;
          let lessons = curso.lessons_count;
          let porcentagem = 0;
          if (lessons_completed !== 0) {
            porcentagem = (lessons_completed / lessons) * 100;
          }
          cursos[x].porcentagem = parseInt(porcentagem, 10);
          cursos[x].has_access = curso.has_access;
          cursos[x].curso = curso;
          let courseAcess = curso.has_access;
          if (
            metaHide === "on" &&
            courseAcess === false &&
            this.permission === false
          ) {
            cursos.splice(x, 1);
          }
        }
        this.AllHome = cursos;
      } else {
        this.withoutCategory = false;
        for (let i = 0; i < categorias.length; i++) {
          const cursos = categorias[i].courses;
          let x = cursos.length;
          while (x--) {
            const curso = cursos[x];
            let lessons_completed = curso.lessons_completed_count;
            let lessons = curso.lessons_count;
            let porcentagem = 0;
            if (lessons_completed !== 0) {
              porcentagem = (lessons_completed / lessons) * 100;
            }
            cursos[x].porcentagem = parseInt(porcentagem, 10);
            let courseAcess = curso.has_access;
            if (
              metaHide === "on" &&
              courseAcess === false &&
              this.permission === false
            ) {
              cursos.splice(x, 1);
            }
          }
        }

        this.AllHome = categorias;
      }
      this.hideCourses = resp.hide_courses;
      this.painelName = resp.name;
      this.countCourse = resp.count_course;
      this.loading = false;
    },
    getHome() {
      if (localStorage.getItem("home") === null) {
        this.loading = true;
      }
      serviceHome
        .search()
        .then((resp) => {
          localStorage.setItem("home", JSON.stringify(resp));
          this.resolveHome(resp);
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("loadOff");
        });
    },
    getHomeVertical() {
      serviceMeta
        .search("keys[]=home_vertical")
        .then((resp) => {
          var data = resp.home_vertical;
          if (data === "off" || data === null) {
            this.homeVertical = false;
          } else {
            this.homeVertical = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getPermission();
    if (localStorage.getItem("home") !== null) {
      this.resolveHome(JSON.parse(localStorage.getItem("home")));
    }
    this.getHome();

    // Cookies.set("themeClub", "light", { expires: 365 });
    // document.documentElement.setAttribute("data-theme", "light");
    // this.$root.$emit("loadsettheme");
  },
};
</script>


<style scoped lang="scss">
.containerGrid {
  display: grid;
  grid-template-columns: 100px calc(100% - 100px);
}
.containerx {
  width: 98%;
}

@media (max-width: 1125px) {
  .containerx {
    width: 88vw;
  }
}

@media (max-width: 955px) {
  .containerx {
    width: 85vw;
  }
}

.spaceHome {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 10px;
}

.spaceHomeMobile {
  width: 100%;
  margin-top: 30px;
  .carousel-inner {
    border-radius: 0px !important;
  }
}

.spaceDivHome {
  margin-top: 50px;
}

.spaceBtnsHome {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  width: 70%;
  margin-left: auto;
  align-items: center;
  padding-right: 30px;
  margin-top: -25px;
  padding-bottom: 50px;
}

.spaceBtnsHome2 {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  width: 60%;
  margin-left: auto;
  align-items: center;
  padding-right: 30px;
  margin-top: -25px;
}

.router-curso {
  text-decoration: none;
}
.btn-NewCourse {
  padding: 20px 23px;
  background: var(--maincolor);
  border: none;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  width: 80%;
  margin-left: auto;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  outline: none;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
.btn-NewCourse svg {
  margin-left: 20px;
  text-decoration: none;
}

.btnGerenciar {
  border: none;
  background: #0000;
  font-family: Montserrat;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--fontcolor);
  padding: inherit;
  span {
    font-weight: 600;
    color: var(--fontcolor);
  }
  svg {
    margin-left: 3px;
    margin-top: -3px;
    position: unset !important;
    path {
      stroke: var(--fontcolor) !important;
    }
  }
}
.container-category {
  margin-top: 305px;
  position: absolute;
  left: 0; 
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container-category {
    margin-top: 475px;
    position: absolute;
    left: 0; 
    width: 100%;
  }
}
</style>
