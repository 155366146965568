var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-course-item-container",class:{
      'card-vertical': _vm.homeVertical,
      'card-horizontal': !_vm.homeVertical,
    }},[_c('div',{staticClass:"card-course-layer-0",class:{
      'card-vertical': _vm.homeVertical,
      'card-horizontal': !_vm.homeVertical,
    },style:(`background-image: url(${_vm.course.cover.cdn_url});`)},[_c('div',{staticClass:"card-course-layer-1",class:{
        'card-vertical': _vm.homeVertical,
        'card-horizontal': !_vm.homeVertical,
      }},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[(_vm.course.has_access)?_c('button',{staticClass:"card-course-btn-view",on:{"click":function($event){return _vm.$router.push(`/curso/${_vm.course.id}`)}}},[_vm._v("Visualizar")]):_vm._e(),(_vm.permission)?_c('button',{staticClass:"card-course-btn-edit",on:{"click":function($event){return _vm.$router.push(`/config_curso/${_vm.course.id}`)}}},[_vm._v("Editar")]):_vm._e()])]),_c('div',{staticClass:"card-course-top-buttons"}),_c('div',{staticClass:"card-course-bottom-buttons pa-3"},[(_vm.course.has_access && _vm.course.is_liberated)?_c('button',{staticClass:"card-course-btn-available green"},[_vm._v("Disponível")]):_vm._e(),(!_vm.course.has_access)?_c('button',{staticClass:"card-course-btn-available green",on:{"click":_vm.clicked}},[_vm._v("Comprar")]):_vm._e(),(!_vm.course.is_liberated)?_c('div',{staticClass:"card-course-btn-lock"},[_c('img',{staticClass:"card-item-lock",attrs:{"src":"lock.svg","alt":"card-locked"}})]):_vm._e()])])]),_c('div',{staticClass:"progress-course-bar-container"},[_c('div',{staticClass:"progress-course-bar-item"},[_c('div',{staticClass:"progress-course-bar-calc",style:(`width: ${100 - parseInt(_vm.course.percentage_release_certificate)}%`)})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }