<template>
  <div class="category-container">
    <span class="category-title">{{ category.title }}</span>
    <HorizontalScroll :showButtonsAt="4">
      <CardCourse
        v-for="(course, i) in category.courses"
        :key="`item-course-${i}`"
        :course="course"
        :ratio="ratio"
        :permission="permission"
        :homeVertical="homeVertical"
      />
    </HorizontalScroll>
  </div>
</template>

<script>
import CardCourse from '../cardCourse/index.vue'; 
import HorizontalScroll from '../horizontalScroll/index.vue'; 
export default {
  props: {
    category: {
      type: Object,
      default: () => {}
    },
    ratio: {
      type: String,
      default: '16:9'
    },
    permission: {
      type: Boolean,
      default: false
    },
    homeVertical: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CardCourse,
    HorizontalScroll,
  }
}
</script>

<style lang="scss">
  .category-container {
    left: 0;  
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .category-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: 32px;
    color: var(--fontcolor);
    margin-left: 21px;
  }
  @media screen and (min-width: 768px) {
    .category-container {
      padding-left: 40px;
      left: 0;  
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
  }
</style>
