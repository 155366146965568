<template>
  <section class="course_status" v-if="show" :style="stylesheet" :is-example="isExample">
    <section class="course_status__content" :has_access="hasAccess">
      <span>{{ hasAccess ? 'Disponível' : 'Compre agora' }}</span>
    </section>
  </section>
</template>

<script>
// Services
import MetaService from '@/services/resources/MetaService';
const serviceMeta = MetaService.build();

export default {
  name: 'IsAvailable',
  props: {
    'has-access': {
      type: Boolean,
      required: true,
      default: () => false,
    },
    'is-example': {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      show: false,
      available_color: "#2133d2",
      not_available_color: "#ffcb37",
    };
  },
  computed: {
    stylesheet() {
      return {
        '--available-color': this.available_color,
        '--not-available-color': this.not_available_color,
      };
    },
  },
  methods: {
    async getMetas() {
      let toSearch = ['is_available_course','available_color', 'not_available_color'];
      let keys = "";
      toSearch.map(item => {
        keys += `keys[]=${item}&`
      })
      await serviceMeta.search(keys).then((resp) => {
        this.available_color = resp.available_color !== undefined ? resp.available_color : "#000"
        this.not_available_color = resp.not_available_color !== undefined ? resp.not_available_color : "#000"
        this.show = resp.is_available_course === 'on' ?? false;
      });
    },
  },
  mounted() {
    this.getMetas();
  },
};
</script>

<style lang="scss" scoped>
.course_status {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute !important;
  bottom: 20px;
  right: 25px;
  z-index: 9;

  &__content {
    min-width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    background: var(--not-available-color);
    padding: 10px;
    border-radius: 5px;
    color: black;

    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;

    &[has_access] {
      background: var(--available-color);
      color: white;
    }
  }

  &[is-example] {
    position: relative !important;
    justify-content: flex-start;
    align-items: center;
    bottom: 0px;
    right: 0px;


    &__content {
      text-align: center;
    }
  }
}
</style>
