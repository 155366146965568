<template>
  <div class="highlight-container">
    <div :class="`highlight-transparency highlight-transparency-${theme}`">
      <Navbar :isMobile="isMobile" />
      <div style="display: flex; align-items: center; width: 100%; height: 100%;">
        <div style="width: 100%; height: 304px;">
          <span :class="`highlight-upper-title highlight-upper-title-${theme}`">CURSO MAIS COMPRADO EM 2023</span>
          <span :class="`highlight-title highlight-title-${theme}`">Não me faça pensar</span>
          <span :class="`highlight-subtitle highlight-subtitle-${theme}`">Não tenha medo de desistir do bom para ir ao grande</span>
          <div class="higilight-button-container">
            <button class="highlight-button-action">
              <img class="play-icon" src="play.svg" alt="play-icon" />
              Assista agora
            </button>
            <button v-if="!isMobile" :class="`highlight-button-info highlight-button-info-${theme}`">Mais informações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navbar from "@/components/V2/navbar/index.vue"
  export default {
    props: ["isMobile"],
    components: {
      Navbar
    },
    computed: {
      theme() {
        return this.$store.state.globalSettings.theme;
      },
      clubVersion() {
        return this.$store.state.globalSettings.clubVersion;
      },
    }
  }
</script>

<style lang="scss">
.highlight-container {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 400px;
  background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.1));
  background-image: url('https://conteudoproduto.magazineluiza.com.br/19/193433700/assets/img/fullhd.jpg');
  background-size: 100 80;
}
.highlight-transparency {
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 400px;
  background-size: 100 80;
}
.highlight-transparency-dark {
  background-image: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0));
  background-size: 100 80;
}
.highlight-transparency-light {
  background-image: linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,0));
  background-size: 100 80;
}

.highlight-upper-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  margin-bottom: 15px;
}
.highlight-upper-title-dark {
  color: rgba(255, 255, 255, 0.5);
}
.highlight-upper-title-light {
  color: rgba(22, 23, 26, 0.5);
}

.highlight-title {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 59px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .highlight-title {
    font-size: 3rem;
    margin-bottom: 25px;
  }
  .highlight-subtitle {
    font-size: 0.7rem;
    white-space: nowrap;
    font-size: 18px;
  }
  .highlight-transparency {
    padding-left: 56px;
    padding-right: 56px;
    height: 550px;
  }
  .highlight-container {
    height: 550px;
  }
}
.highlight-title-dark {
  color: #FFFFFF;
}
.highlight-title-light {
  color: #16171A;
}

.highlight-subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.higilight-button-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 32px;
}
.highlight-button-action {
  box-sizing: border-box;
  height: 50px;
  border: none;
  white-space: nowrap;
  background: #01CAA6;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #00483B;
  border-radius: 50px;
  margin-right: 16px;
  padding-right: 38px;
  padding-left: 28px;
}
.highlight-button-info {
  box-sizing: border-box;
  height: 50px;
  font-weight: 600;
  border-radius: 50px;
  white-space: nowrap;
  padding-right: 28px;
  padding-left: 28px;
  background: rgba(0,0,0,0);
}
.highlight-button-info-dark {
  border: 1px solid #FFFFFF;
  color: #fff;
}
.highlight-button-info-light {
  border: 1px solid #16171A;
  color: #16171A;
}

.highlight-subtitle-dark {
  color: #fff ;
}
.highlight-subtitle-light {
  color: #16171A;
}
.play-icon {
  margin-right: 12px;
}
</style>
