<template>
  <div class="spaceBanner">
    <vue-aspect-ratio ar="16:4" width="100%" v-if="!isMobile">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="9000"
        controls
        indicators
        background="#3330"
        img-width="1080"
        img-height="640"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="item in AllBanners"
          :key="item.id"
          style="height: fit-content"
        >
          <template #img v-if="item.has_access">
            <a
              :href="item.link"
              target="_blank"
              class="w-100 d-block img-fluid"
              v-if="item.type_banner === 'link'"
            >
              <img class="w-100 d-block img-fluid" :src="item.media.cdn_url" />
            </a>
            <a
              @click="openModalBuy(item)"
              target="_blank"
              class="w-100 d-block img-fluid"
              v-else
            >
              <img class="w-100 d-block img-fluid" :src="item.media.cdn_url" />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </vue-aspect-ratio>
    <vue-aspect-ratio ar="9:5" width="100%" v-if="isMobile">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="9000"
        controls
        indicators
        background="#3330"
        img-width="1080"
        img-height="640"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="item in AllBanners"
          :key="item.id"
          style="height: fit-content"
        >
          <template #img v-if="item.has_access">
            <a
              :href="item.link"
              target="_blank"
              class="w-100 d-block img-fluid"
              v-if="item.type_banner === 'link'"
            >
              <img
                v-if="item.mobile_media"
                class="w-100 d-block img-fluid"
                :src="item.mobile_media.cdn_url"
              />
              <img
                v-else
                class="w-100 d-block img-fluid"
                :src="item.media.cdn_url"
              />
            </a>
            <a
              @click="openModalBuy(item)"
              target="_blank"
              class="w-100 d-block img-fluid"
              v-else
            >
              <img
                v-if="item.mobile_media"
                class="w-100 d-block img-fluid"
                :src="item.mobile_media.cdn_url"
              />
              <img
                v-else
                class="w-100 d-block img-fluid"
                :src="item.media.cdn_url"
              />
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </vue-aspect-ratio>
  </div>
</template>

<script>
import VueAspectRatio from "vue-aspect-ratio";
import BannerService from "@/services/resources/BannerService";
const serviceBanner = BannerService.build();
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  props: ["AllBanners", "isMobile"],
  components: {
    "vue-aspect-ratio": VueAspectRatio,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    openModalBuy(item) {
      if (item.product_banner) {
        var id = JSON.parse(item.product_banner).id;
        var data = item.id + "/meta?keys[]=type_banner";
        serviceBanner
          .read(data)
          .then((resp) => {
            if (resp.type_banner === "tela") {
              this.$router.push(
                "/curso/venda/" + JSON.parse(item.product_banner).course_id
              );
            } else {
              serviceCourse
                .read(JSON.parse(item.product_banner).course_id)
                .then((resp) => {
                  this.$root.$emit("modal-buy-course", resp);
                  this.$root.$emit("bv::show::modal", "modal-buy", "#btnShow");
                })
                .catch((err) => {
                  //console.log(err);
                  this.$root.$emit("loadOff");
                });
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss">
.spaceBanner {
  margin-top: 30px;
  .carousel-inner {
    border-radius: 5px;
  }
  .carousel.slide {
    height: 100%;
  }

  .carousel-inner {
    height: 100%;
  }
  .carousel-item {
    height: 100%;
    img {
      min-height: 100%;
      object-fit: cover;
    }
  }
}
</style>