<template>
  <div
    class="horizontal-scroll"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mousemove="drag"
    @touchmove="drag"
    @mouseup="endDrag"
    @touchend="endDrag"
  >
    <!-- <button @click="scrollLeft" class="scroll-button left" ref="leftButton" v-show="showButtons && righClicked > 0">
      <i class="fas fa-chevron-left"></i>
    </button> -->
    <div
      class="content-scroll"
      ref="content"
      @scroll="checkShowButtons"
      @touchmove="handleScroll"
    >
      <slot></slot>
    </div>
    <!-- <button @click="scrollRight" class="scroll-button right" ref="rightButton" v-show="showButtons && !hiddeRightBtn">
      <i class="fas fa-chevron-right"></i>
    </button> -->
  </div>
</template>

<script>
export default {
  name: 'HorizontalScroll',
  props: {
    showButtonsAt: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      righClicked: 0,
      hiddeRightBtn: false,
      checking: false,
      showButtons: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0
    };
  },
  mounted() {
    this.checkShowButtons();
  },
  beforeUnmount() {
    this.$refs.content.removeEventListener('scroll', this.checkShowButtons);
  },
  methods: {
    checkShowButtons() {
      const contentWidth = this.$refs.content.scrollWidth;
      const containerWidth = this.$refs.content.clientWidth;
      const slots = this.$slots.default ? this.$slots.default.length : false;
      const showButtons = contentWidth > containerWidth && slots > this.showButtonsAt;
      if (this.showButtons !== showButtons) {
        this.showButtons = showButtons;
      }
    },
    scrollLeft() {
      this.hiddeRightBtn = false;
      this.$refs.content.scrollBy({ left: -540, behavior: 'smooth' });
      setTimeout(() => {
        this.checking = true;
        this.checkScroller('-');
      }, 100);
    },
    scrollRight() {
      this.$refs.content.scrollBy({ left: 540, behavior: 'smooth' });
      setTimeout(() => {
        this.checking = true;
        this.checkScroller('+');
      }, 100);
    },
    handleScroll() {
      if (!this.checking) {
        this.scrolled();
      }
    },
    scrolled() {
      this.righClicked++;
    },
    checkScroller(type) {
      if (this.righClicked > 0) {
        switch (type) {
          case '+':
            this.righClicked++;
            break;
          case '-':
            this.righClicked--;
            break;
          default:
            return;
        }
      }

      if (type === '+' && this.righClicked === 0) {
        this.hiddeRightBtn = true;
        this.$refs.content.scrollBy({ left: -5000, behavior: 'easy' });
      }
      if (type === '-' && this.righClicked === 0) {
        this.hiddeRightBtn = false;
        this.$refs.content.scrollBy({ left: 5000, behavior: 'easy' });
      }
      this.checking = false;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startDragX = event.clientX || event.touches[0].clientX;
      this.startScrollLeft = this.$refs.content.scrollLeft;
    },
    drag(event) {
      if (this.isDragging) {
        const scrollAmount = this.startScrollLeft + (this.startDragX - (event.clientX || event.touches[0].clientX));
        this.$refs.content.scrollLeft = scrollAmount;
      }
    },
    endDrag() {
      this.isDragging = false;
    }
  }
};
</script>

<style>
.horizontal-scroll {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  padding-left: 17px;
}

.content-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: 100%;
  overflow: hidden;
  right: 0;
  z-index: 30;
}

.scroll-button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: var(--maincolor);
  font-size: 1rem;
  z-index: 999;
  background: rgba(0,0,0,0.3);
}

.left {
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
}

.right {
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  margin-top: 80px;
}
</style>
