<template>
  <div 
  >
    <div class="card-course-item-container"
      :class="{
        'card-vertical': homeVertical,
        'card-horizontal': !homeVertical,
      }"
    >
      <div class="card-course-layer-0"
      :class="{
        'card-vertical': homeVertical,
        'card-horizontal': !homeVertical,
      }"
      :style="`background-image: url(${course.cover.cdn_url});`"
      >
        <div class="card-course-layer-1"
          :class="{
          'card-vertical': homeVertical,
          'card-horizontal': !homeVertical,
        }">
          <div style="display: flex; flex-wrap: wrap">
            <button @click="$router.push(`/curso/${course.id}`)" v-if="course.has_access" class="card-course-btn-view">Visualizar</button>
            <button @click="$router.push(`/config_curso/${course.id}`)" v-if="permission" class="card-course-btn-edit">Editar</button>
          </div>
        </div>
        <div class="card-course-top-buttons"></div>
        <div class="card-course-bottom-buttons pa-3">
          <button v-if="course.has_access && course.is_liberated" class="card-course-btn-available green">Disponível</button>
          <button @click="clicked" v-if="!course.has_access" class="card-course-btn-available green">Comprar</button>
          <div v-if="!course.is_liberated" class="card-course-btn-lock">
            <img class="card-item-lock" src="lock.svg" alt="card-locked" />
          </div>
        </div>
      </div>
    </div>
    <div class="progress-course-bar-container">
      <div class="progress-course-bar-item">
        <div class="progress-course-bar-calc" :style="`width: ${100 - parseInt(course.percentage_release_certificate)}%`"  >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notify from '@/services/libs/notificacao';
export default {
  props: {
    course: {
      type: Object,
      default: () => {}
    },
    homeVertical: {
      type: Boolean,
      default: false
    },
    permission: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    containerRatio() {
      const [width, height] = this.ratio.split(":");
      const aspectRatio = (parseInt(height) / parseInt(width)) * 100;
      return `${aspectRatio}%`;
    },
  },
  methods: {
    clicked() {
      if (!this.course.has_access) {
        if (this.course?.course_id) {
          this.openModalBuy(this.course, true);
        } else {
          this.openModalBuy(this.course);
        }
        return;
      }
    },
    openModalBuy(data, module) {
      if (!data.is_liberated) {
        notify('erro', this.$t('home2.course_template.error.liberation'));
      } else {
        notify('erro', this.$t('home2.course_template.error.access'));
        if (module) {
          this.$root.$emit('buy-course-modal-v2', data.curso);
        } else {
          this.$root.$emit('buy-course-modal-v2', data);
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .card-course-item-container {
    position: relative;
    border-radius: 7px;
    z-index: 20;
    margin-right: 7px;
    overflow: hidden;
  }
  .card-vertical {
    min-width: 128px;
    width: 128px;
    height: 165px;
  }
  .card-horizontal {
    min-width: 201px;
    width: 201px;
    height: 119px;
  }
  @media screen and (min-width: 768px) {
    .card-vertical {
      min-width: 236px;
      width: 236px;
      height: 304px;
    }
    .card-horizontal {
      min-width: 317px;
      width: 317px;
      height: 188px;
    }
  }
  
  .card-course-layer-0 {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    left: 0;top:0;
    border-radius: 6px;
    background: rgba(0,0,0,0.1);
    z-index: 22;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
  }
  .card-course-layer-0:hover {
    transform: scale(1.1);
    .card-course-layer-1 {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 6px;
      z-index: 22;
      position: absolute;
      transition: background-color 0.3s ease;
      visibility: visible;
    }
    .card-course-layer-1:hover {
      background-color: rgba(0,0,0,0.3)
    }
    .card-course-btn-available {
      visibility: hidden;
    }

  }
  .card-course-layer-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    z-index: 22;
    position: absolute;
    transition: background-color 0.3s ease;
    visibility: hidden;
  }
  .card-course-btn-available {
    height: 25px;
    border: none;
    white-space: nowrap;
    background: #3483FA;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #00483B;
    border-radius: 50px;
    padding-right: 13px;
    padding-left: 13px;
    transition: visibility ease;
  }
  .card-course-btn-view {
    height: 25px;
    border: none;
    white-space: nowrap;
    background: rgba(215, 215, 215, 0.1);
    backdrop-filter: blur(10px);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50px;
    padding-right: 13px;
    padding-left: 13px;
  }
  .card-course-btn-edit {
    height: 25px;
    border: none;
    white-space: nowrap;
    background: rgba(215, 215, 215, 0.1);
    backdrop-filter: blur(10px);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50px;
    padding-right: 13px;
    padding-left: 13px;
    margin-left: 13px;
  }

  .card-course-top-buttons {
    position: relative;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    
  }
  .card-course-bottom-buttons {
    position: relative;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .green {
    background: #01CAA6;
  }

  .card-course-btn-lock {
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(30, 32, 36, 0.5);
  }
  .card-item-lock {
    width: 13px;
    height: 15px;
  }

  .progress-course-bar-container {
    position: relative; 
    display: flex; 
    bottom: 0;
    width: 100%;
    padding-right: 7px; 
    margin-top: 7px;
  }
  .progress-course-bar-item {
    display: flex;
    border-radius: 6px;
    height: 2px; z-index: 80;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .progress-course-bar-calc {
    display: flex; 
    border-radius: 6px; 
    height: 2px; 
    z-index: 81; 
    background: #01CAA6;
  } 
</style>
